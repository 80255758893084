import React from 'react';
import { Link } from 'gatsby';
import { Helmet } from 'react-helmet';

const qRecipes = () => {
  return (
    <div className="container">
      <Helmet>
        <title> The Shoyaright! </title>
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@zuffshoya" />
        <meta name="twitter:title" content="3 Quarantine Recipes" />
        <meta name="twitter:image" content="http://www.shoya.co/static/shoyaright_icon-ada66d9717351d5e161cdd4c5d83603b.png" />
        <meta name="twitter:image:alt" content="The Shoyaright! Logo" />
      </Helmet>
      <div className="article">
        <Link to="/shoyaright">
        <div className="shoyarightChomskyArticle" />
        </Link>
        <div className="articleTitle">
          <p>3 Quarantine Recipes</p>
        </div>
        <div className="byLine">
          <p> By: Lil Shoyaright! (Pub. Apr. 19, 2020) </p>
        </div>
        <div className="articleBody">
          <p> No platitudes but look we gotta stay nourished during all this so look I'm saying look here I go look: </p>
          <p> I. DATES Á LA CARTE</p>
          <p> Yeah y'all ain't think I knew what á la carte meant did y'all? Anyway I know Ramadan is coming up so y'all either got some of these in the crib already or you got a grocery run coming up -- here's what you need for this recipe: </p>
          <p className="highlight"><i> 10 Medjool dates, pitted </i></p>
          <p> So yeah that's it just go in. Any of y'all who say "ew they look like roaches" do me a favor, go head and grow up. </p>
          <p> II. CASHEWS Á LA CARTE </p>
          <p> See? I really do know what it means, I wasn't playing. Y'all think I would keep using a phrase I only have a vague understanding of, without looking it up? On this, the most prestigious journal on the Internet? I been knowing the meaning for a long time now. I already knew it. I knew it. I knew it. La la la la I can't hear you I knew it.</p>
          <p className="highlight"><i> 20 whole cashews </i></p>
          <p> Enjoy this source of healthy fats, but know that they're more calorie-dense than most other nuts. If you got a jump-rope in the crib though, yehmean, do you. </p>
          <p> III. DATE & CASHEW MEDLEY </p>
          <p> If you know me, you already knew this was coming. Pull out: </p>
          <p className="highlight"><i> 10 Medjool dates, pitted <br></br>30 whole cashews</i></p>
          <p> So first you wanna julienne the cashews and get started with reducing the dates -- all of these words I know the meanings of by the way. Ard ard jk lmao, obviously you just put em in a bowl and have at em. The quantities here don't mean shit really, I just recommend keeping the ratio 3:1 cashews:dates. </p>
          <p> Fun fact, that last recipe contains the exact same ingredients as a Senzu bean. Bon appétit.</p>

        </div>
        <p className="plug">If you enjoyed this article, consider <a href="https://www.patreon.com/join/shoyaright?">becoming a patron</a> or making a <a href="https://www.paypal.com/cgi-bin/webscr?cmd=_donations&business=zuff@shoya.co&lc=US&item_name=The%20Shoyaright%21&currency_code=USD&no_note=0&bn=PP-DonationsBF:btn_donateCC_LG.gif:NonHostedGuest">one-time pledge</a>. Support corporate journalism!</p>
        <div className="articleCopyright">
          <p>&copy; Shoya, Inc. 2020</p>
        </div>
      </div>
    </div>

  );
};
export default qRecipes;
